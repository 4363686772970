import Cookies from 'js-cookie';

export const readFromCookie = (name) => {
    return Cookies.get(name);
}

export const writeToCookie = (name, value) => {
    const isCookieEnabled = readFromCookie('gdpr-analytics-enabled');

    if (name === 'gdpr-analytics-enabled' && value.toString() === 'true') {
        Cookies.set(name.toString(), value.toString());
    }

    if (isCookieEnabled === 'true') {
        Cookies.set(name.toString(), value.toString());
    }
}