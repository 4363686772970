// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-505-js": () => import("./../../../src/pages/505.js" /* webpackChunkName: "component---src-pages-505-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-big-data-analytics-js": () => import("./../../../src/pages/big-data-analytics.js" /* webpackChunkName: "component---src-pages-big-data-analytics-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-twins-js": () => import("./../../../src/pages/digital-twins.js" /* webpackChunkName: "component---src-pages-digital-twins-js" */),
  "component---src-pages-ephesus-suite-js": () => import("./../../../src/pages/ephesus-suite.js" /* webpackChunkName: "component---src-pages-ephesus-suite-js" */),
  "component---src-pages-hymots-js": () => import("./../../../src/pages/hymots.js" /* webpackChunkName: "component---src-pages-hymots-js" */),
  "component---src-pages-hymots-solutions-js": () => import("./../../../src/pages/hymots-solutions.js" /* webpackChunkName: "component---src-pages-hymots-solutions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nginr-js": () => import("./../../../src/pages/nginr.js" /* webpackChunkName: "component---src-pages-nginr-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-training-simulation-js": () => import("./../../../src/pages/training-simulation.js" /* webpackChunkName: "component---src-pages-training-simulation-js" */)
}

