import React from "react"

import { writeToCookie, readFromCookie } from "./cookieHelper"

let defaultGlobalState = {
  lightThemeState: undefined,
  tabIndex: 0,
  cookieBannerStatus:
    readFromCookie("gdpr-analytics-enabled") === "true" ? false : true,
  displayNewsModal:
    readFromCookie("displayNewsModal") === "false" ? false : true,
}

const globalStateContext = React.createContext(defaultGlobalState)
const dispatchStateContext = React.createContext(undefined)

export const useGlobalState = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchStateContext),
]

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (state, newValue) => {
      if (
        newValue &&
        newValue.lightThemeState !== null &&
        newValue.lightThemeState !== undefined
      ) {
        writeToCookie("theme", newValue.lightThemeState)
      }
      //   if (
      //     newValue &&
      //     newValue.displayNewsModal !== null &&
      //     newValue.displayNewsModal !== undefined
      //   ) {
      //     writeToCookie("displayNewsModal", newValue.displayNewsModal)
      //   }
      return { ...state, ...newValue }
    },

    defaultGlobalState
  )

  return (
    <globalStateContext.Provider value={state}>
      <dispatchStateContext.Provider value={dispatch}>
        {children}
      </dispatchStateContext.Provider>
    </globalStateContext.Provider>
  )
}

export default GlobalStateProvider
